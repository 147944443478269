import React, { useState, useEffect } from "react";
import classNames from "classnames";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { ListItem, MailchimpForm, Pagination } from "@molecules";
import { Container, Dropdown, Text } from "@atoms";
import { AnimatePresence, m } from "framer-motion";

const getUids = options => options.map(option => option.uid);

const FilteredList = ({ items, className: _className }) => {
  const { audiences, topics } = useCategoriesData();
  const audienceUids = ["all", ...getUids(audiences)];
  const topicUids = ["all", ...getUids(topics)];

  const types = [
    { uid: "all", title: "All" },
    { uid: "dataReport", title: "Data Report" },
    { uid: "firesideChat", title: "Fireside Chat" },
    { uid: "pressRelease", title: "Press Release" },
    { uid: "article", title: "Article" },
    { uid: "default", title: "Resource" },
    { uid: "webinar", title: "Webinar" },
    { uid: "whitepaper", title: "Whitepaper" },
  ];

  const [audience, setAudience] = useState(0);
  const [type, setType] = useState(0);
  const [topic, setTopic] = useState(0);
  const urlFilter =
    typeof window !== "undefined" ? window.location?.hash?.split("#")[1] : null;
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items?.filter(
        item =>
          (audience === 0 ||
            item.audiences?.includes(audienceUids[audience])) &&
          (type === 0 || item.type === types[type].uid) &&
          (topic === 0 || item.topics?.includes(topicUids[topic]))
      )
    );
  }, [audience, type, topic]);

  // set topic based on the url parameter
  useEffect(() => {
    if (urlFilter) {
      setTopic(topics.findIndex(t => t.uid === urlFilter));
    }
  }, [urlFilter]);

  const defaults = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3,
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <section className={classNames("py-12 md:py-20", _className)}>
      <Container>
        <div className="flex flex-wrap gap-24">
          {/* resources */}
          <div className="flex-1">
            {/* filters */}
            <div className="flex flex-wrap gap-4 md:flex-nowrap">
              <div className="flex w-full items-center space-x-2 md:flex-1">
                <Text variant="label" className="text-carnation">
                  Audience:
                </Text>
                <Dropdown
                  size="sm"
                  model={[audience, setAudience]}
                  options={[{ title: "All", uid: "0" }, ...audiences]}
                />
              </div>
              <div className="flex w-full items-center space-x-2 md:flex-1">
                <Text variant="label" className="text-carnation">
                  Type:
                </Text>
                <Dropdown size="sm" model={[type, setType]} options={types} />
              </div>
              <div className="flex w-full items-center space-x-2 md:flex-1">
                <Text variant="label" className="text-carnation">
                  Topic:
                </Text>
                <Dropdown
                  size="sm"
                  model={[topic, setTopic]}
                  options={[{ title: "All", uid: "0" }, ...topics]}
                />
              </div>
            </div>
            {/* filtered items */}
            <div className="mt-12 flex flex-col gap-12">
              <AnimatePresence exitBeforeEnter>
                <ul className="grid w-full grid-cols-1 gap-12">
                  {!!filteredItems.length && (
                    <Pagination
                      component={ListItem}
                      data={filteredItems}
                      pageSize={10}
                      rangeLimit={2}
                      showNavigation
                      variants={variants}
                    />
                  )}
                  {!filteredItems?.length && (
                    <m.li
                      initial="exit"
                      animate="enter"
                      exit="exit"
                      variants={variants}
                      className="col-span-2 flex flex-col items-center justify-center"
                    >
                      <Text variant="xl">
                        There are no resources that match your selected filters.
                      </Text>
                    </m.li>
                  )}
                </ul>
              </AnimatePresence>
            </div>
          </div>
          {/* form */}
          <div className="w-full lg:w-1/3">
            <MailchimpForm heading="Newsletter Signup" />
          </div>
        </div>
      </Container>
    </section>
  );
};

FilteredList.defaultProps = {};

export default FilteredList;
